import {ISystemReducer} from "./index";
import {createReducer} from "@reduxjs/toolkit";
import actions from 'src/redux/actions/SystemActions'

const localStoreHarvest = "current_harvest";

const initialState: ISystemReducer = {
    openHarvest: false,
    currentHarvest: Number(window.localStorage.getItem(localStoreHarvest)) || 0,
    isAuth: false,
    drawerCollapsed: false
};

export default createReducer(initialState, builder => {
    builder.addCase(actions.SET_AUTHENTICATION, (state, action) => {
        state.isAuth = action.payload;
    }).addCase(actions.SET_DRAWER_COLLAPSED, (state, action) => {
        state.drawerCollapsed = action.payload;
    }).addCase(actions.SET_OPEN_HARVEST_DIALOG, (state, action) => {
        state.openHarvest = action.payload;
    }).addCase(actions.SET_CURRENT_HARVEST, (state, action) => {
        window.localStorage.setItem(localStoreHarvest, action.payload.toString());
        state.currentHarvest = action.payload;
    });
});
