import {deserialize} from "cerialize";

export default class HarvestSetting {
    @deserialize
    harvest: number;
    @deserialize
    farm: number;
    @deserialize
    plot: number;
    @deserialize
    culture: number;
    @deserialize
    planting: number;
    @deserialize
    cycle: number;
    @deserialize
    area: number;
}