import {createMuiTheme, Theme} from "@material-ui/core";
import {ptBR} from "@material-ui/data-grid";
import {fade} from "@material-ui/core/styles";

const primaryColor = "#00AB55";
const secondaryColor = "#005249";
const borderRadius = 16;

const defaultTheme = createMuiTheme();

const theme: Theme = createMuiTheme({
    typography: {
        fontFamily: 'Be Vietnam, Arial',
        body1: {
            fontSize: '0.9rem',
            fontWeight: 500
        },
        body2: {
            fontSize: '0.85rem',
            fontWeight: 400
        }
    },
    shape: {
        borderRadius: borderRadius
    },
    palette: {
        text: {
            primary: '#53615a',
            secondary: '#84968d'
        },
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        }
    },
    mixins: {
        toolbar: {
            height: 64
        }
    },
    props: {
        MuiPaper: {
            elevation: 3
        }
    },
    overrides: {
        MuiToolbar: {
            root: {}
        },
        MuiIcon: {
            root: {
                fontSize: '1.2rem',
                height: 'auto'
            }
        },
        MuiList: {
            padding: {
                paddingLeft: defaultTheme.spacing(1),
                paddingRight: defaultTheme.spacing(1),
            }
        },
        MuiListItem: {
            root: {
                lineHeight: '1rem',
                borderRadius: borderRadius,
                "&$selected": {
                    background: fade(secondaryColor, 0.2),
                    color: primaryColor
                }
            },
        },
        MuiMenuItem: {
            root: {
                borderRadius: 0
            }
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                minWidth: 40
            }
        }
    },
}, ptBR);

export default theme;
