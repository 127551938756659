import {deserialize, deserializeAs} from "cerialize";

export default class CollectionPoint{
    constructor(description:string) {
        this.description = description;
    }

    @deserializeAs("ref")
    id: number;

    @deserialize
    description: string;

    @deserialize
    farm: number;
}
