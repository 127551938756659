import {deserialize, deserializeAs} from "cerialize";

export default class FieldApplicationType {
    constructor(description: string) {
        this.description = description;
    }

    @deserializeAs("ref")
    id: number;

    @deserialize
    description: string;

    @deserialize
    planting: boolean;
}
