import * as React from "react";
import {Backdrop, CircularProgress, createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFFFFF',
    }
}), {classNamePrefix: 'loading-progress'});

export default function LoadingProgress({show = true}: IProps): React.ReactElement<IProps> {
    const classes = useStyles();

    return <Backdrop className={classes.root} open={show}>
        <CircularProgress color="primary"/>
    </Backdrop>
}

interface IProps {
    show?: boolean;
}