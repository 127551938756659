import {IRainReducer} from "./index";
import {createReducer} from "@reduxjs/toolkit";
import RainActions from "src/redux/actions/RainActions";

const initialState: IRainReducer = {
    data: [],
}

export default createReducer(initialState, builder => {
    builder.addCase(RainActions.SET_RAINS_LIST, (state, action) => {
        state.data = action.payload;
    }).addCase(RainActions.CLEAR_RAINS, (state, action) => {
        state.data = [];
    });
});
