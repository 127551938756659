import {IApplicationReducer} from "./index";
import {createReducer} from "@reduxjs/toolkit";
import ApplicationActions from "src/redux/actions/ApplicationActions";

const initialState: IApplicationReducer = {
    data: [],
    applicationPlot: [],
    applicationItem: [],
    applicationId: '',
    applicationControlNumber: 0
}

export default createReducer(initialState, builder => {
    builder.addCase(ApplicationActions.SET_APPLICATIONS_LIST, (state, action) => {
        state.data = action.payload;
    }).addCase(ApplicationActions.CLEAR_APPLICATIONS, (state, action) => {
        state.data = [];
    }).addCase(ApplicationActions.SET_APPLICATION_PLOT_LIST, (state, action) => {
        state.applicationPlot = action.payload;
    }).addCase(ApplicationActions.CLEAR_APPLICATION_PLOT, (state, action) => {
        state.applicationPlot = [];
    }).addCase(ApplicationActions.SET_APPLICATION_ITEM_LIST, (state, action) => {
        state.applicationItem = action.payload;
    }).addCase(ApplicationActions.CLEAR_APPLICATION_ITEM, (state, action) => {
        state.applicationItem = [];
    }).addCase(ApplicationActions.SET_APPLICATION_ID, (state, action) => {
        state.applicationId = action.payload;
    }).addCase(ApplicationActions.CLEAR_APPLICATION_ID, (state, action) => {
        state.applicationId = '';
    }).addCase(ApplicationActions.SET_APPLICATION_CONTROL_NUMBER, (state, action) => {
        state.applicationControlNumber = action.payload;
    }).addCase(ApplicationActions.CLEAR_APPLICATION_CONTROL_NUMBER, (state, action) => {
        state.applicationControlNumber = 0;
    });
});
