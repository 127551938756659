import {createAction} from "@reduxjs/toolkit";

const SystemActions = {
    SET_OPEN_HARVEST_DIALOG: createAction<boolean>('SET_OPEN_HARVEST_DIALOG'),
    SET_CURRENT_HARVEST: createAction<number>('SET_CURRENT_HARVEST'),
    SET_AUTHENTICATION: createAction<boolean>('SET_AUTHENTICATION'),
    SET_DRAWER_COLLAPSED: createAction<boolean>('SET_DRAWER_COLLAPSED')
}

export default SystemActions;
