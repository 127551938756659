import {IRecommendationReducer} from "./index";
import {createReducer} from "@reduxjs/toolkit";
import RecommendationActions from "src/redux/actions/RecommendationActions";

const initialState: IRecommendationReducer = {
    recommendation: []
}

export default createReducer(initialState, builder => {
    builder.addCase(RecommendationActions.SET_RECOMMENDATION_LIST, (state, action) => {
        state.recommendation = action.payload;
    }).addCase(RecommendationActions.CLEAR_RECOMMENDATION, (state, action) => {
        state.recommendation = [];
    });
});
