import {deserialize, deserializeAs} from "cerialize";

export class ResultHarvestCost {
    @deserialize
    description: string = "Test de Descrição";

    @deserialize
    expected: number = 0;

    @deserialize
    done: number = 0;
}

export class ResultHarvestIncome {
    @deserialize
    expected: number = 0;

    @deserialize
    done: number = 0;
}

export default class ResultHarvest {
    @deserialize
    plot: number = 0;

    @deserialize
    harvest: number = 0;

    @deserialize
    productivity: number = 0;

    @deserialize
    area: number = 0;

    @deserializeAs(ResultHarvestIncome)
    income: ResultHarvestIncome = new ResultHarvestIncome();

    @deserializeAs(ResultHarvestCost)
    costs: ResultHarvestCost[] = [new ResultHarvestCost(),new ResultHarvestCost()];
}