import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {Provider} from "react-redux";
import store from 'src/redux/store';
import theme from 'src/resources/themeConfig';
import DateFnsUtils from '@date-io/date-fns';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ptBR} from 'date-fns/locale';
import "@mdi/font/css/materialdesignicons.min.css";

ReactDOM.render(
    <React.Fragment>
        <CssBaseline/>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <SnackbarProvider maxSnack={2} anchorOrigin={{horizontal: "right", vertical: "top"}} autoHideDuration={3000}>
                        <App/>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);

