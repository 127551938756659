import {createReducer} from "@reduxjs/toolkit";
import DashboardActions from "src/redux/actions/DashboardActions";
import {IDashboardReducer} from "./index";
import ResultHarvest from "src/models/ResultHarvest";
import {PlotComparisonTotal} from "src/models/PlotComparison";

const initialState: IDashboardReducer = {
    areaApplied: [],
    plotItems: [],
    plantingProgress: [],
    resultHarvest: new ResultHarvest(),
    cyclesMap: [],
    plotComparison: [],
    plotComparisonTotal: new PlotComparisonTotal()
}

export default createReducer(initialState, builder => {
    builder.addCase(DashboardActions.SET_AREA_DATE, (state, action) => {
        state.areaApplied = action.payload;
    }).addCase(DashboardActions.SET_PLOT_ITEM, (state, action) => {
        state.plotItems = action.payload;
    }).addCase(DashboardActions.SET_PLANTING_PROGRESS, (state, action) => {
        state.plantingProgress = action.payload;
    }).addCase(DashboardActions.SET_RESULT_HARVEST, (state, action) => {
        state.resultHarvest = action.payload;
    }).addCase(DashboardActions.SET_CYCLES_MAP, (state, action) => {
        state.cyclesMap = action.payload;
    }).addCase(DashboardActions.SET_PLOT_COMPARISON, (state, action) => {
        state.plotComparison = action.payload;
    }).addCase(DashboardActions.CLEAR_COMPARISONS, (state, action) => {
        state.plotComparison = [];
    }).addCase(DashboardActions.SET_PLOT_COMPARISON_TOTAL, (state, action) => {
        state.plotComparisonTotal = action.payload;
    }).addCase(DashboardActions.CLEAR_PLOT_COMPARISON_TOTAL, (state, action) => {
        state.plotComparisonTotal = new PlotComparisonTotal();
    });
});
