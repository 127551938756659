import {createAction} from "@reduxjs/toolkit";
import Application, {ApplicationAppointment, ApplicationItem, ApplicationPlot} from "src/models/Application";

const ApplicationActions = {
    SET_APPLICATIONS_LIST: createAction<Application[]>('SET_APPLICATIONS_LIST'),
    CLEAR_APPLICATIONS: createAction<void>('CLEAR_APPLICATIONS'),
    SET_APPLICATION_PLOT_LIST: createAction<ApplicationPlot[]>('SET_APPLICATION_PLOT_LIST'),
    CLEAR_APPLICATION_PLOT: createAction<void>('CLEAR_APPLICATION_PLOT'),
    SET_APPLICATION_ITEM_LIST: createAction<ApplicationItem[]>('SET_APPLICATION_ITEM_LIST'),
    CLEAR_APPLICATION_ITEM: createAction<void>('CLEAR_APPLICATION_ITEM'),
    SET_APPLICATION_ID: createAction<string>('SET_APPLICATION_ID'),
    CLEAR_APPLICATION_ID: createAction<void>('CLEAR_APPLICATION_ID'),
    SET_APPLICATION_CONTROL_NUMBER: createAction<number>('SET_APPLICATION_CONTROL_NUMBER'),
    CLEAR_APPLICATION_CONTROL_NUMBER: createAction<void>('CLEAR_APPLICATION_CONTROL_NUMBER')
}

export default ApplicationActions;
