import {createAction} from "@reduxjs/toolkit";
import AreaDate from "src/models/AreaDate";
import PlotItem from "src/models/PlotItem";
import PlantingProgress from "src/models/PlantingProgress";
import ResultHarvest from "src/models/ResultHarvest";
import CycleMap from "src/models/CycleMap";
import PlotComparison, {PlotComparisonTotal} from "src/models/PlotComparison";

const DashboardActions = {
    SET_AREA_DATE: createAction<AreaDate[]>('SET_AREA_DATE'),
    SET_PLOT_ITEM: createAction<PlotItem[]>('SET_PLOT_ITEM'),
    SET_PLANTING_PROGRESS: createAction<PlantingProgress[]>('SET_PLANTING_PROGRESS'),
    SET_RESULT_HARVEST: createAction<ResultHarvest>('SET_RESULT_HARVEST'),
    SET_CYCLES_MAP: createAction<CycleMap[]>('SET_CYCLES_MAP'),
    SET_PLOT_COMPARISON: createAction<PlotComparison[]>('SET_PLOT_COMPARISON'),
    CLEAR_COMPARISONS: createAction<void>('CLEAR_COMPARISONS'),
    SET_PLOT_COMPARISON_TOTAL: createAction<PlotComparisonTotal>('SET_PLOT_COMPARISON_TOTAL'),
    CLEAR_PLOT_COMPARISON_TOTAL: createAction<void>('CLEAR_PLOT_COMPARISON_TOTAL'),
}

export default DashboardActions;
