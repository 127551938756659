import Axios, {AxiosInstance, AxiosResponse, AxiosError, AxiosPromise} from "axios";
import Authentication from "./Authentication";
import qs from "qs";

export default class Restful {
    private instance: AxiosInstance;

    constructor() {
        const token = Authentication.getToken();
        this.instance = Axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 30000,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": Boolean(token) ? token : "",
            },
            paramsSerializer: (param: any) => {
                return qs.stringify(param, {arrayFormat: 'repeat'});
            },
        });
    }

    public get(path: string, params: Object = {}): Promise<RestfulResponse> {
        const promise = this.instance.get(path, {params: params});
        return this.build(promise);
    }

    public delete(path: string, params: Object = {}): Promise<RestfulResponse> {
        const promise = this.instance.delete(path, {params: params});
        return this.build(promise);
    }

    public post(path: string, object: any, params?: any): Promise<RestfulResponse> {
        const response = new RestfulResponse();

        return new Promise<RestfulResponse>((resolve, reject) => {
            this.instance.post(path, object, {params: params}).then((value: AxiosResponse) => {
                const object = value.data
                response.code = value.status;
                response.body = object['data'];
                response.message = object['message'];
                response.error = false;
                resolve(response);
            }).catch((error: AxiosError) => {
                const request: XMLHttpRequest = error.request;
                const object = JSON.parse(request.responseText);
                response.code = request.status;
                response.error = true;
                response.message = object['message'];
                response.body = {};
                reject(response);
            })
        });
    }

    public put(path: string, object: any, params?: any): Promise<RestfulResponse> {
        const promise = this.instance.put(path, object,{params:params});
        return this.build(promise);
    }

    private build(promise: AxiosPromise<any>): Promise<RestfulResponse> {
        const response = new RestfulResponse();

        return promise.then((value: AxiosResponse) => {
            response.code = value.status;
            response.body = value.data['data'];
            response.message = value.data['message'];
            response.error = false;
            return response;

        }).catch((error: AxiosError) => {

            const request: XMLHttpRequest = error.request;
            response.code = request.status;
            if (error.response) {
                response.body = error.response.data['data'];
                response.message = error.response.data['message'];
            }
            response.error = true;
            return response;
        });
    }
}

export class RestfulResponse {
    error: boolean = false;
    code: number | string | undefined;
    body: any;
    message: string = "";
}
