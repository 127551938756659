import SystemReducer from "src/redux/reducers/SystemReducer";
import Application, {ApplicationAppointment, ApplicationItem, ApplicationPlot} from "src/models/Application";
import ApplicationReducer from "src/redux/reducers/ApplicationReducer";
import Asset from "src/models/Asset";
import Culture from "src/models/Culture";
import Cycle from "src/models/Cycle";
import FieldApplicationType from "src/models/FieldApplicationType";
import Harvest from "src/models/Harvest";
import Operator from "src/models/Operator";
import Planting from "src/models/Planting";
import Plot from "src/models/Plot";
import Product from "src/models/Product";
import ServiceNoteType from "src/models/ServiceNoteType";
import StockManagement from "src/models/StockManagement";
import Farm from "src/models/Farm";
import RegisterReducer from "src/redux/reducers/RegisterReducer";
import AreaDate from "src/models/AreaDate";
import PlotItem from "src/models/PlotItem";
import DashboardReducer from "src/redux/reducers/DashboardReducer";
import PlantingProgress from "src/models/PlantingProgress";
import ResultHarvest from "src/models/ResultHarvest";
import HarvestSetting from "src/models/HarvestSetting";
import CycleMap from "src/models/CycleMap";
import PlotComparison, {PlotComparisonTotal} from "src/models/PlotComparison";
import {Recommendation} from "src/models/Recommendation";
import RecommendationReducer from "src/redux/reducers/RecommendationReducer";
import Contact from "src/models/Contact";
import CollectionPoint from "src/models/CollectionPoint";
import Rain from "../../models/Rain";
import RainReducer from "./RainReducer";

export interface IRecommendationReducer {
    recommendation: Recommendation[]
}

export interface IDashboardReducer {
    areaApplied: AreaDate[];
    plotItems: PlotItem[];
    plantingProgress: PlantingProgress[];
    resultHarvest: ResultHarvest;
    cyclesMap:CycleMap[];
    plotComparison: PlotComparison[];
    plotComparisonTotal: PlotComparisonTotal;
}

export interface IApplicationReducer {
    data: Application[];
    applicationItem: ApplicationItem[];
    applicationPlot: ApplicationPlot[];
    applicationId: string;
    applicationControlNumber: number;
}

export interface IRainReducer {
    data: Rain[];
}

export interface IRegisterReducer {
    farms: Farm[];
    assets: Asset[];
    cultures: Culture[];
    cycles: Cycle[];
    fieldApplicationTypes: FieldApplicationType[];
    harvests: Harvest[];
    operators: Operator[];
    plantings: Planting[];
    plots: Plot[];
    products: Product[];
    serviceNoteTypes: ServiceNoteType[];
    stockManagements: StockManagement[];
    harvestSettings: HarvestSetting[];
    contacts: Contact[];
    collectionPoints: CollectionPoint[];
}

export interface ISystemReducer {
    currentHarvest: number;
    openHarvest: boolean;
    isAuth: boolean;
    drawerCollapsed: boolean;
}

export interface IReducer {
    state: any;
    system: ISystemReducer;
    register: IRegisterReducer;
    application: IApplicationReducer;
    dashboard: IDashboardReducer;
    recommendation: IRecommendationReducer;
    rain: IRainReducer;
}

const reducer = {
    system: SystemReducer,
    register: RegisterReducer,
    application: ApplicationReducer,
    dashboard: DashboardReducer,
    recommendation: RecommendationReducer,
    rain: RainReducer
}

export default reducer;
