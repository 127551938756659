import {deserialize, deserializeAs} from "cerialize";

export default class Cycle{
    constructor(description:string) {
        this.description = description;
    }

    @deserializeAs("ref")
    id: number;

    @deserialize
    description: string;
}
