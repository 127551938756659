import * as React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {IReducer} from "./redux/reducers";
import Authentication from "src/resources/Authentication";
import SystemActions from "src/redux/actions/SystemActions";
import {Redirect, Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import RegisterDao from "src/daos/RegisterDao";
import {useSnackbar} from "notistack";
import {ParamCallback} from "src/typing/global";
import LoadingProgress from 'src/components/LoadingProgress';

const LoginPage = React.lazy(() => import("src/pages/LoginPage"));
const HomePage = React.lazy(() => import("src/pages/HomePage"));
const MapPage = React.lazy(() => import("src/pages/MapPage"));
const SettingPage = React.lazy(() => import("src/pages/SettingPage"));
const ApplicationPage = React.lazy(() => import("src/pages/ApplicationPage"));
const PlotComparisonPage = React.lazy(() => import("src/pages/PlotComparisonPage"));
const RecommendationPage = React.lazy(() => import("src/pages/RecommendationPage"));
const RecommendationFormMain = React.lazy(() => import("src/components/recommendation/RecommendationFormMain"));
const RainPage = React.lazy(() => import("src/pages/RainPage"));
const RainFormMain = React.lazy(() => import("src/components/rain/RainFormMain"));

export default function App(): React.ReactElement {
    const dispatch = useDispatch();
    const isAuth = useSelector<IReducer, boolean>(state => state.system.isAuth);
    const [loading, setLoading] = React.useState<boolean>(true);
    const {enqueueSnackbar} = useSnackbar();

    const loadData = React.useCallback<ParamCallback<void, void>>(() => {
        Promise.all([
            RegisterDao.getAssets(),
            RegisterDao.getCultures(),
            RegisterDao.getCycles(),
            RegisterDao.getFarms(),
            RegisterDao.getFieldApplicationTypes(),
            RegisterDao.getHarvests(),
            RegisterDao.getOperators(),
            RegisterDao.getPlantings(),
            RegisterDao.getPlots(),
            RegisterDao.getProducts(),
            RegisterDao.getServiceNoteTypes(),
            RegisterDao.getStockManagements(),
            RegisterDao.getHarvestSetting(),
            RegisterDao.getContacts(),
            RegisterDao.getCollectionPoint(),
        ]).then(() => {
            enqueueSnackbar('Informaçoes atualizadas');
        }).catch((reason) => {
            console.error(reason);
            enqueueSnackbar('Erro ao baixar informações', {variant: "error"});
        }).finally(() => {

        });
    }, [enqueueSnackbar]);

    React.useEffect(() => {
        Authentication.isAuth().then(() => {
            dispatch(SystemActions.SET_AUTHENTICATION(true));
        }).catch(() => {
            dispatch(SystemActions.SET_AUTHENTICATION(false));
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch]);

    React.useEffect(() => {
        if (isAuth) loadData();
    }, [isAuth]);

    if (loading) {
        return <LoadingProgress/>;
    }

    return <React.Suspense fallback={<LoadingProgress/>}>
            <BrowserRouter>
                <Switch>
                    {isAuth && <Route exact path={'/'} render={() => <Redirect to={'/home'}/>}/>}
                    {!isAuth && <Route exact path={'/'} render={() => <Redirect to={'/login'}/>}/>}

                    <PrivateRoute path={'/login'} isAuth={!isAuth} component={LoginPage}/>
                    <PrivateRoute path={'/home'} isAuth={isAuth} component={HomePage}/>
                    <PrivateRoute path={'/map'} isAuth={isAuth} component={MapPage}/>
                    <PrivateRoute path={'/application'} isAuth={isAuth} component={ApplicationPage}/>
                    <PrivateRoute path={'/setting'} isAuth={isAuth} component={SettingPage}/>
                    <PrivateRoute path={'/plot_comparison'} isAuth={isAuth} component={PlotComparisonPage}/>
                    <PrivateRoute path={'/recommendation'} isAuth={isAuth} component={RecommendationPage}/>
                    <PrivateRoute path={`/recommendation_form`} isAuth={isAuth} component={RecommendationFormMain} />
                    <PrivateRoute path={'/rain'} isAuth={isAuth} component={RainPage}/>
                    <PrivateRoute path={`/rain_form`} isAuth={isAuth} component={RainFormMain}/>

                    <Route exact path={'/*'} render={() => <Redirect to={'/'}/>}/>
                </Switch>
            </BrowserRouter>
        </React.Suspense>;
}

function PrivateRoute(props: IPropsPrivateRoute): React.ReactElement<IPropsPrivateRoute> {

    function redirect() {
        return <Redirect to={'/'}/>
    }

    function component() {
        const Component = props.component;
        return <Component/>
    }

    return <Route exat={true} path={'/'} render={props.isAuth ? component : redirect}/>
}

interface IPropsPrivateRoute {
    path: string;
    isAuth: boolean;
    component: React.ComponentType<any>;
}
