import {deserialize, deserializeAs} from "cerialize";

export default class Farm {
    constructor(description: string) {
        this.description = description;
    }

    @deserializeAs("ref")
    id: number;

    @deserialize
    description: string;
}
