import {createAction} from "@reduxjs/toolkit";
import Farm from "src/models/Farm";
import Asset from "src/models/Asset";
import Culture from "src/models/Culture";
import Cycle from "src/models/Cycle";
import FieldApplicationType from "src/models/FieldApplicationType";
import Harvest from "src/models/Harvest";
import Operator from "src/models/Operator";
import Planting from "src/models/Planting";
import Plot from "src/models/Plot";
import Product from "src/models/Product";
import ServiceNoteType from "src/models/ServiceNoteType";
import StockManagement from "src/models/StockManagement";
import HarvestSetting from "src/models/HarvestSetting";
import Contact from "src/models/Contact";
import CollectionPoint from "src/models/CollectionPoint";

const RegisterActions = {
    SET_FARMS: createAction<Farm[]>('SET_FARMS'),
    SET_ASSETS: createAction<Asset[]>('SET_ASSETS'),
    SET_CULTURES: createAction<Culture[]>('SET_CULTURES'),
    SET_CYCLES: createAction<Cycle[]>('SET_CYCLE'),
    SET_FIELD_APPLICATION_TYPES: createAction<FieldApplicationType[]>('SET_FIELD_APPLICATION_TYPES'),
    SET_HARVESTS: createAction<Harvest[]>('SET_HARVESTS'),
    SET_OPERATORS: createAction<Operator[]>('SET_OPERATORS'),
    SET_PLANTINGS: createAction<Planting[]>('SET_PLANTINGS'),
    SET_PLOTS: createAction<Plot[]>('SET_PLOTS'),
    SET_PRODUCTS: createAction<Product[]>('SET_PRODUCTS'),
    SET_SERVICE_NOTE_TYPES: createAction<ServiceNoteType[]>('SET_SERVICE_NOTE_TYPES'),
    SET_STOCK_MANAGEMENTS: createAction<StockManagement[]>('SET_STOCK_MANAGEMENTS'),
    SET_HARVEST_SETTING: createAction<HarvestSetting[]>('SET_HARVEST_SETTING'),
    SET_CONTACT: createAction<Contact[]>('SET_CONTACT'),
    SET_COLLECTION_POINT: createAction<CollectionPoint[]>('SET_COLLECTION_POINT'),
}

export default RegisterActions;
