import {IRegisterReducer} from "./index";
import {createReducer} from "@reduxjs/toolkit";
import RegisterActions from "src/redux/actions/RegisterActions";

const initialState: IRegisterReducer = {
    assets: [],
    cultures: [],
    cycles: [],
    farms: [],
    fieldApplicationTypes: [],
    harvests: [],
    operators: [],
    plantings: [],
    plots: [],
    products: [],
    serviceNoteTypes: [],
    stockManagements: [],
    harvestSettings:[],
    contacts: [],
    collectionPoints: []
}

export default createReducer(initialState, builder => {
    builder.addCase(RegisterActions.SET_FARMS, (state, action) => {
        state.farms = action.payload;
    }).addCase(RegisterActions.SET_ASSETS, (state, action) => {
        state.assets = action.payload;
    }).addCase(RegisterActions.SET_CULTURES, (state, action) => {
        state.cultures = action.payload;
    }).addCase(RegisterActions.SET_CYCLES, (state, action) => {
        state.cycles = action.payload;
    }).addCase(RegisterActions.SET_FIELD_APPLICATION_TYPES, (state, action) => {
        state.fieldApplicationTypes = action.payload;
    }).addCase(RegisterActions.SET_HARVESTS, (state, action) => {
        state.harvests = action.payload;
    }).addCase(RegisterActions.SET_PRODUCTS, (state, action) => {
        state.products = action.payload;
    }).addCase(RegisterActions.SET_SERVICE_NOTE_TYPES, (state, action) => {
        state.serviceNoteTypes = action.payload;
    }).addCase(RegisterActions.SET_STOCK_MANAGEMENTS, (state, action) => {
        state.stockManagements = action.payload;
    }).addCase(RegisterActions.SET_PLOTS, (state, action) => {
        state.plots = action.payload;
    }).addCase(RegisterActions.SET_OPERATORS, (state, action) => {
        state.operators = action.payload;
    }).addCase(RegisterActions.SET_HARVEST_SETTING, (state, action) => {
        state.harvestSettings = action.payload;
    }).addCase(RegisterActions.SET_PLANTINGS, (state, action) => {
        state.plantings = action.payload;
    }).addCase(RegisterActions.SET_CONTACT, (state, action) => {
        state.contacts = action.payload;
    }).addCase(RegisterActions.SET_COLLECTION_POINT, (state, action) => {
        state.collectionPoints = action.payload;
    });
});
