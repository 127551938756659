import Restful from "src/resources/Restful";
import {Deserialize} from "cerialize";
import Farm from "src/models/Farm";
import RegisterActions from "src/redux/actions/RegisterActions";
import Asset from "src/models/Asset";
import Culture from "src/models/Culture";
import Cycle from "src/models/Cycle";
import FieldApplicationType from "src/models/FieldApplicationType";
import Harvest from "src/models/Harvest";
import Operator from "src/models/Operator";
import Planting from "src/models/Planting";
import Plot from "src/models/Plot";
import Product from "src/models/Product";
import ServiceNoteType from "src/models/ServiceNoteType";
import StockManagement from "src/models/StockManagement";
import store from "src/redux/store";
import HarvestSetting from "../models/HarvestSetting";
import Contact from "../models/Contact";
import CollectionPoint from "../models/CollectionPoint";

export default class RegisterDao {
    public static async getFarms(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/farm');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Farm));
            store.dispatch(RegisterActions.SET_FARMS(data));
        }
    }

    public static async getAssets(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/asset');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Asset));
            store.dispatch(RegisterActions.SET_ASSETS(data));
        }
    }

    public static async getCultures(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/culture');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Culture));
            store.dispatch(RegisterActions.SET_CULTURES(data));
        }
    }

    public static async getCycles(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/cycle');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Cycle));
            store.dispatch(RegisterActions.SET_CYCLES(data));
        }
    }

    public static async getFieldApplicationTypes(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/field_application_type');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, FieldApplicationType));
            store.dispatch(RegisterActions.SET_FIELD_APPLICATION_TYPES(data));
        }
    }

    public static async getHarvests(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/harvest');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Harvest));
            store.dispatch(RegisterActions.SET_HARVESTS(data));
        }
    }

    public static async getOperators(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/operator');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Operator));
            store.dispatch(RegisterActions.SET_OPERATORS(data));
        }
    }

    public static async getPlantings(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/planting');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Planting));
            store.dispatch(RegisterActions.SET_PLANTINGS(data));
        }
    }

    public static async getPlots(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/plot');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Plot));
            store.dispatch(RegisterActions.SET_PLOTS(data));
        }
    }

    public static async getProducts(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/product');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Product));
            store.dispatch(RegisterActions.SET_PRODUCTS(data));
        }
    }

    public static async getServiceNoteTypes(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/service_note_type');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, ServiceNoteType));
            store.dispatch(RegisterActions.SET_SERVICE_NOTE_TYPES(data));
        }
    }

    public static async getStockManagements(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/stock_management');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, StockManagement));
            store.dispatch(RegisterActions.SET_STOCK_MANAGEMENTS(data));
        }
    }

    public static async getHarvestSetting(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/harvest_setting');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, HarvestSetting));
            store.dispatch(RegisterActions.SET_HARVEST_SETTING(data));
        }
    }

    public static async getContacts(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/contact');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, Contact));
            store.dispatch(RegisterActions.SET_CONTACT(data));
        }
    }

    public static async getCollectionPoint(): Promise<void> {
        const rest = new Restful();
        const response = await rest.get('/collection_point');
        if (response.code !== 200) throw response.message;
        if (Array.isArray(response.body)) {
            const data = response.body.map((obj) => Deserialize(obj, CollectionPoint));
            store.dispatch(RegisterActions.SET_COLLECTION_POINT(data));
        }
    }
}
