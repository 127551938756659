import {deserialize, deserializeAs} from "cerialize";

export default class Contact{
    constructor(description:string, phone:string) {
        this.description = description;
        this.phone = phone;
    }

    @deserializeAs("ref")
    id: number;

    @deserializeAs('nome')
    description: string;

    @deserialize
    phone: string;
}
